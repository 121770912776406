const chronologyJP = [
    {
        year: 1912,
        event: '兵庫県豊岡市に生まれる。'
    },
    {
        year: 1937,
        event: '上田桑鳩に師事し、書を学ぶ。東京に転居。'
    },
    {
        year: 1938,
        event: '「日満支書道展」で文部大臣賞を受賞。'
    },
    {
        year: 1944,
        event: '郷里豊岡に疎開。'
    },
    {
        year: 1948,
        event: '「書の美」創刊（〜1952年）'
    },
    {
        year: 1950,
        event: `京都に転居。井島勉、久松真一を知る。`
    },
    {
        year: 1951,
        event: '「墨美」創刊（〜1981年）'
    },
    {
        year: 1952,
        event: `森田子龍・井上有一・江口草玄・関谷義道・中村木子の五名により、墨人会結成。`
    },
    {
        year: 1962,
        event: `ドイツ政府の招聘を受け訪独。`
    },
    {
        year: 1963,
        event: `欧米諸国を歴訪し、講演、揮毫の実演、映像の上映を行う。`
    },
    {
        year: 1968,
        event: `一年間にわたり、NHKテレビで書の講義を担当。`
    },
    {
        year: 1971,
        event: `『自選作品　子龍』刊行。`
    },
    {
        year: 1975,
        event: `展覧会図録「森田子龍の書」刊行。<br/>編集を担当した「近代の美術28　書と墨象」刊行。`
    },
    {
        year: 1978,
        event: `京都府美術工芸功労者の表彰を受ける。`
    },
    {
        year: 1980,
        event: `京都市より文化功労者の表彰を受ける。<br/>ロジェ・カイヨワとの共著『印』刊行。`
    },
    {
        year: 1981,
        event: `臨書研究誌「龍門」創刊（〜1984年）`
    },
    {
        year: 1984,
        event: `「蒼龍社」を設立、後進の指導にあたる。`
    },
    {
        year: 1995,
        event: `兵庫県文化賞受賞。`
    },
    {
        year: 1998,
        event: `86歳で逝去。`
    },
    {
        year: 2000,
        event: `紺綬褒章の追贈を受ける。`
    }
];

export default chronologyJP;