const EndnotesJP = (props) => {
    const text = props.text;

    return (
        <div className='endnotesJP'>
            {text}
            <br /> 
            <br />
        </div>
    );
};

export default EndnotesJP;