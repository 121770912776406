import mainImage from '../../../../assets/mainvisual.jpg'

const MainVisual = () => {
    return (
        <div className='fullwidthContainerstyle'>
            <img src={mainImage} alt='' />
        </div>
    )
};

export default MainVisual;