import Chapter01 from './part04Sections/Chapter01';
import Chapter02 from './part04Sections/Chapter02';
import Chapter03 from './part04Sections/Chapter03';
import Chapter04 from './part04Sections/Chapter04';

const Part04 = () => {
    return (
        <>
            <Chapter01 />
            <Chapter02 />
            <Chapter03 />
            <Chapter04 />
        </>
    );
};

export default Part04;
