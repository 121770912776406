const exhibitionHistoryJP = [
    {
        year: 1953,
        event: `サロン・ド・オクトーブル、クラヴァンギャラリー（パリ）`
    },
    {
        year: 1954,
        event: `日本の書、ニューヨーク近代美術館<br/>
        第1回墨人展、丸善画廊（東京・京都）`
    },
    {
        year: 1955,
        event: `日米抽象美術展、東京国立近代美術館<br/>
        ファーズ国際展（パリ）<br/>
        墨人海外展、コレットアランディギャラリー（パリ）・アポロギャラリー（ブリュッセル）<br/>
        現代日本の書・墨の芸術展、東京国立近代美術館（後、ヨーロッパ各地巡回）`
    },
    {
        year: 1957,
        event: `墨人海外展、ワールドハウスギャラリー（ニューヨーク）`
    },
    {
        year: 1958,
        event: `抽象絵画の展開展、東京国立近代美術館<br/>
        カーネギー国際現代絵画彫刻展（ピッツバーグ）`
    },
    {
        year: 1959,
        event: `第5回サンパウロビエンナーレ、サンパウロ近代美術館`
    },
    {
        year: 1961,
        event: `第6回サンパウロビエンナーレ、サンパウロ近代美術館<br/>
        カーネギー国際現代絵画彫刻展（ピッツバーグ）<br/>
        現代日本の墨画展（アメリカ各地巡回）`
    },
    {
        year: 1962,
        event: `意味と記号展、マティルデンヘーエ（ダルムシュタット）他、ドイツ各地巡回<`
    },
    {
        year: 1963,
        event: `書法と形象展、バーデン・バーデン国立美術館・アムステルダム市立美術館<br/>
        個展、米舟画廊（ニューヨーク）`
    },
    {
        year: 1964,
        event: `個展、山田画廊（京都）・京都府ギャラリー<br/>
        現代日本絵画展、コーコラン美術館（ワシントンDC）他、アメリカ各地巡回`
    },
    {
        year: 1965,
        event: `個展、フットヴァルカーギャラリー（フランクフルト）<br/>
        森田・菅井・飯田展、ブルースベルグギャラリー（ハノーバー）`
    },
    {
        year: 1966,
        event: `第1回ジャパンアートフェスティバル（アメリカ各地巡回）`
    },
    {
        year: 1967,
        event: `万国博覧会国際美術展（モントリオール）<br/>
        第2回ジャパンアートフェスティバル（アメリカ各地巡回）`
    },
    {
        year: 1968,
        event: `第3回ジャパンアートフェスティバル（メキシコ各地巡回）<br/>
        明治百年記念：東西美術交流展、東京国立近代美術館`
    },
    {
        year: 1969,
        event: `カナダ諸都市巡回個展、オタワ国立美術館他（カナダ諸都市）`
    },
    {
        year: 1970,
        event: `万国博覧会国際美術展、万国博美術館（大阪）<br/>
        個展、ハウス・アム・リュツォプラッツ（ベルリン）`
    },
    {
        year: 1972,
        event: `第7回ジャパンアートフェスティバル（メキシコシティ・ブエノスアイレス ）`
    },
    {
        year: 1975,
        event: `個展、朝日画廊（京都）<br/>
        第10回ジャパンアートフェスティバル（ニュージーランド・オーストラリア巡回）`
    },
    {
        year: 1976,
        event: `20世紀現代日本の書展、クリングスポール美術館（オッフェンバッハ）他ドイツ各地巡回`
    },
    {
        year: 1986,
        event: `今日の作家３　森田子龍展、京都市美術館ギャラリー106`
    },
    {
        year: 1992,
        event: `森田子龍と『墨美』展、兵庫県立近代美術館（神戸）`
    },
    {
        year: 2012,
        event: `生誕100年森田子龍展、兵庫県立円山川公苑美術館（豊岡）`
    },
    {
        year: 2013,
        event: `文字の力・書のチカラＩＩ：書と絵画の対話、出光美術館（東京）`
    },
    {
        year: 2016,
        event: `あの時みんな熱かった！アンフォルメルと日本の美術、京都国立近代美術館`
    },
    {
        year: 2017,
        event: `Abstract Expressionism: Looking East from Far West、ホノルル美術館<br/>
        The Weight of Lightness、Ｍ+美術館（香港）`
    },
    {
        year: 2018,
        event: `森田子龍：心の書、清荒神清澄寺資料館（宝塚）`
    }
]

export default exhibitionHistoryJP;